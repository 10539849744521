import {Component, OnInit} from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { SnotifyPosition, SnotifyService, SnotifyToastConfig } from 'ng-snotify';
import { environment } from "../../../../../../environments/environment";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { TranslateService } from '@ngx-translate/core';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ContractService } from 'src/app/Alkira-Tenant/services/contract.service';
// import {
//   SocialAuthService,
//   GoogleLoginProvider,
//   SocialUser,
// } from 'angularx-social-login';
@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig],
})
export class NavRightComponent implements OnInit {
  first_name: any;
  last_name: any;
  selected_laguage: any = 'fr';

  // display notification start
  style = 'material';
  title: any;
  body: any;
  timeout = 2000;
  position: SnotifyPosition = SnotifyPosition.rightTop;
  progressBar = true;
  closeClick = true;
  newTop = true;
  filterDuplicates = false;
  backdrop = -1;
  dockMax = 2;
  blockMax = 6;
  pauseHover = false;
  titleMaxLength = 15;
  bodyMaxLength = 40;
  // display notification end
  message: any = null;
  // title = 'af-notification';
  notificationList = [];
  notificationlist: any;
  selected_laguage_value: string;
  constructor(private router: Router,
    private snotifyService: SnotifyService,
    public translateService:TranslateService,
    private ngxLoader: NgxUiLoaderService,
    private contractservice: ContractService
    // private socialAuthService: SocialAuthService,
    ) {}

  ngOnInit() {
    this.first_name = window.localStorage.getItem('first_name');
    this.last_name = window.localStorage.getItem('last_name');

    if (window.localStorage.getItem('app_language')) {
      this.selected_laguage = window.localStorage.getItem('app_language');
    }
    console.log('hhh', this.notificationList);
    if (JSON.parse(window.localStorage.getItem('notificationList')) === null) {
      console.log('null');
    } else {
      this.notificationlist = JSON.parse(
        window.localStorage.getItem('notificationList')
      );
      console.log(this.notificationlist);
    }
    this.first_name = window.localStorage.getItem('first_name');
    this.last_name = window.localStorage.getItem('last_name');
    // this.requestPermission();
    this.listen();
  }

  logout() {
    window.localStorage.clear();
    this.router.navigate(['/auth/login']);
    // this.socialAuthService.signOut();
  }

  profile() {
    this.router.navigate(['/my-profile']);
  }

  changeLanguage(){
    this.contractservice.updateadminlanguage(this.selected_laguage_value).subscribe(res => {
      if (res.status === true) {
        this.ngxLoader.stopAll();
        Swal.fire('', res.message, 'success');
        console.log(res);
        if(this.selected_laguage_value=='ENG'){
          window.localStorage.setItem('app_language', 'en');
          window.location.reload();
        }else{
          window.localStorage.setItem('app_language', 'fr');
          window.location.reload();
        }
      
      
      } else {
        this.ngxLoader.stopAll();
        console.log(res);
        this.translateService.get('app').subscribe((translate) => {
          this.translateService.get('app').subscribe((translate) => {
            Swal.fire({
              icon: 'error',
              title: translate.oops,
              text: res.message
            });
          });
        });
      }
    }, error => {
      this.ngxLoader.stopAll();
      this.translateService.get('app').subscribe((translate) => {
        Swal.fire({
          icon: 'error',
          title: translate.oops,
          text: translate.error_msg
        });
      });
      console.error(error);
    });
   }

  englishLang() {
   
    this.selected_laguage_value='ENG';
    this.changeLanguage();
 
  }
  frenchLang() {
  
    this.selected_laguage_value='FR';
    this.changeLanguage();

  }

  requestPermission() {
    const messaging = getMessaging();
    getToken(messaging, { vapidKey: environment.firebase.vapidKey })
      .then((currentToken) => {
        if (currentToken) {
          console.log('Hurraaa!!! we got the token.....');
          console.log(currentToken);
        } else {
          console.log(
            'No registration token available. Request permission to generate one.'
          );
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  }
  listen() {
    console.log('Message received. ')
    const messaging = getMessaging();
    console.log('Message received. ', messaging);
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      this.message = payload;
      this.body = this.message.notification.body;
      console.log(this.body);
      this.title = this.message.notification.title;
      
      this.notificationList.push(this.message.notification);
        console.log( this.notificationList);
        this.notificationList.reverse();
        console.log( this.notificationList);
        window.localStorage.setItem('notificationList', JSON.stringify(this.notificationList));
        this.notificationlist = JSON.parse(window.localStorage.getItem('notificationList'));
        console.log('if', this.notificationlist);
        this.snotifyService.success(this.body, this.title, this.getConfig());
      // this.snotifyService.success(this.body, this.title, this.getConfig());

      // if (
      //   JSON.parse(window.localStorage.getItem('notificationList')) === null
      // ) {
      //   this.notificationList.push(this.message.notification);
      //   console.log(this.notificationList);
      //   this.notificationList.reverse();
      //   console.log(this.notificationList);
      //   window.localStorage.setItem(
      //     'notificationList',
      //     JSON.stringify(this.notificationList)
      //   );
      //   console.log('if', this.notificationList);
      // } else {
      //   this.notificationList.push(this.message.notification);
      //   this.notificationList.reverse();
      //   console.log(this.notificationList);
      //   window.localStorage.setItem(
      //     'notificationList',
      //     JSON.stringify(this.notificationList)
      //   );
      //   this.notificationlist = JSON.parse(
      //     window.localStorage.getItem('notificationList')
      //   );
      //   console.log('else', this.notificationlist);
      // }
      // this.notificationlist = JSON.parse(window.localStorage.getItem('notificationList'));
      // console.log(this.notificationlist);
    });
  }

  // display notification after change status
  getConfig(): SnotifyToastConfig {
    this.snotifyService.setDefaults({
      global: {
        newOnTop: this.newTop,
        maxAtPosition: this.blockMax,
        maxOnScreen: this.dockMax,
        // @ts-ignore
        filterDuplicates: this.filterDuplicates,
      },
    });
    return {
      bodyMaxLength: this.bodyMaxLength,
      titleMaxLength: this.titleMaxLength,
      backdrop: this.backdrop,
      position: this.position,
      timeout: this.timeout,
      showProgressBar: this.progressBar,
      closeOnClick: this.closeClick,
      pauseOnHover: this.pauseHover,
    };
  }
}
