import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import {AuthComponent} from './theme/layout/auth/auth.component';
import { AuthGuard } from './Alkira-Tenant/guards/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        loadChildren: () => import('./Alkira-Tenant/dashboard/dashboard.module').then(module => module.DashboardModule)
      },
      {
        path: 'contract',
        loadChildren: () => import('./Alkira-Tenant/contract-management/contract-management.module').then(module => module.ContractManagementModule)
      },
      {
        path: 'inventory',
        loadChildren: () => import('./Alkira-Tenant/inventory-management/inventory-management.module').then(module => module.InventoryManagementModule)
      },
      {
        path: 'repair',
        loadChildren: () => import('./Alkira-Tenant/repair/repair.module').then(module => module.RepairModule)
      },
      {
        path: 'my-profile',
        loadChildren: () => import('./Alkira-Tenant/my-profile/my-profile.module').then(module => module.MyProfileModule)
      },
    
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      // {
      //   path: '',
      //   redirectTo: 'auth',
      //   pathMatch: 'full'
      // },
      {
        path: 'auth',
        loadChildren: () => import('./Alkira-Tenant/authentication/authentication.module').then(module => module.AuthenticationModule)
      }
     
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
