import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-show-photos',
  templateUrl: './show-photos.component.html',
  styleUrls: ['./show-photos.component.scss']
})
export class ShowPhotosComponent implements OnInit {
  @Input() image: any;
  slickConfig = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    autoplay: true,
    autoplaySpeed: 2000, // Set the autoplay speed in milliseconds
    arrows: true
    // Add more configuration options as needed
  };
  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void {

    console.log("image",this.image)
  }
}
