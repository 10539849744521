import { Injectable } from '@angular/core';
import { baseURL, timeoutTime } from '../../config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/timeoutWith';
import 'rxjs/add/operator/timeout';
@Injectable({
  providedIn: 'root'
})
export class ContractService {

  baseUrl = baseURL;
  constructor(private httpClient: HttpClient) {
  }

  getcontractpagination(dataTablesParameters): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `contract_update/tenant/pagination `, dataTablesParameters, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getcontractpagination')));
  }

  getbyidcontract(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.get(this.baseUrl + `contract_update/${id}`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getbyidcontract')));
  }

  contractinventorylist(id, dataTablesParameters): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put(this.baseUrl + `contract_update/tenant_inventory_list/${id}`, dataTablesParameters, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error contractinventorylist')));
  }

  digitalsigncontract(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put(this.baseUrl + `contract_update/digitally_sign/${id}`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error digitalsigncontract')));
  }

  startnoticeperiod(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put(this.baseUrl + `contract_update/start_notice_period/${id}`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error startnoticeperiod')));
  }

  verifysigncontractotp( data): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put(this.baseUrl + `contract_update/verify_sign_otp`, data, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error verifysigncontractotp')));
  }

  verifystartnoticeperiodcontractotp( data): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put(this.baseUrl + `contract_update/verify_end_contract_otp`, data, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error verifystartnoticeperiodcontractotp')));
  }
  digitalsigncontractinventory(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put(this.baseUrl + `contract_update/digitally_sign/inventory_contract/${id}`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error digitalsigncontractinventory')));
  }
  verifycontractinventoryotp( data): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put(this.baseUrl + `contract_update/verify_sign_otp/inventory_contract`, data, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error verifycontractinventoryotp')));
  }

  createcontractnewstep2(data,id): Observable<any> {
    // const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `contract_update/upload_sign_tenant/`+id, data)
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error createcontract')));
  }

  signInvetory(data,id): Observable<any> {
    // const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `contract_update/inventorycontract/upload_sign_tenant/`+id, data)
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error createcontract')));
  }
  archievecontract(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put(this.baseUrl + `contract_update/tenant/archieve/${id}`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error archievecontract')));
  }

  updateadminlanguage(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `tenant/change_lang/${id}`, {}, { } )
    .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error updateadmin')));
  }

  contractinventoryItemlist(id, dataTablesParameters): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `contractitem/tenant/pagination/${id}`, dataTablesParameters, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error contractinventorylist')));
  }


   //for new COntract Inventory
   getAccommodationByID(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `accommodation/tenant/list/${id}`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error itemlistbymanager')));
  }

  addcontractinventorynew(data,id): Observable<any> {
    // const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put(this.baseUrl + `contractitem/tenant/${id}`, data)
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error addcontractinventory')));
  }

  contractinventorylistaccommodation(id, dataTablesParameters): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `contractitem/tenant/pagination/${id}`, dataTablesParameters, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error contractinventorylist')));
  }
  
  changecontractinventoryStatusaccomodation(id, statusdata): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `contractitem/tenant/change_status/${id}`, statusdata, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error changecontractinventoryStatus')));
  }

  
  deletecontractinventoryaccomodation(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.delete(this.baseUrl + `contractitem/${id}`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error deletecontract')));
}


getbyidinventory(id): Observable<any> {
  const headers = new HttpHeaders().set('Content-Type', 'application/json');
  return this.httpClient.get(this.baseUrl + `contractitem/${id}`, { headers })
    .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getbyidcontract')));
}

getContractUrl(id): Observable<any> {
  const headers = new HttpHeaders().set('Content-Type', 'application/json');
  return this.httpClient.get(this.baseUrl + `contract_update/get_contract_pdf/${id}`, { headers })
    .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error changecontractinventoryStatus')));
}

getContractInventoryUrl(id): Observable<any> {
  const headers = new HttpHeaders().set('Content-Type', 'application/json');
  return this.httpClient.get(this.baseUrl + `contract_update/get_inventory_contract_pdf/${id}`, { headers })
    .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error changecontractinventoryStatus')));
}

}
