<div class="modal-header">
    <button type="button" class="close" (click)="modal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="text-align: center;">
          <!-- <ngb-carousel>
            <ng-template style="text-align: center;" ngbSlide> -->
              <img [src]="image" alt="" class="img-fluid" />
            <!-- </ng-template>
          </ngb-carousel>      -->
  </div>
