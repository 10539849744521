<ul class="navbar-nav ml-auto">
  <li>{{first_name}}  {{last_name}}</li>
  <li>
    <div class="dropdown drp-user" ngbDropdown placement="auto">
      <a href="javascript:" ngbDropdownToggle data-toggle="dropdown">
        <i class="icon feather icon-user"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-right profile-notification" ngbDropdownMenu>
        <div class="pro-head">
          <img src="assets/images/user/alkira_symbol_white.svg" class="img-radius" alt="User-Profile-Image">
          <span>{{first_name}}  {{last_name}}</span>
          <!-- <a href="javascript:" class="dud-logout" title="Logout">
            <i class="feather icon-log-out"></i>
          </a> -->
        </div>
        <ul class="pro-body">
          <li><a href="javascript:" class="dropdown-item"
            (click)="profile()"><i class="feather icon-user"></i>{{'View Profile' | translate}}</a></li>
          <!-- <li><a href="javascript:" class="dropdown-item"><i class="feather icon-settings m-r-5"></i> Settings </a></li> -->
          <li><a href="javascript:" class="dropdown-item" (click)="logout()"><i class="feather icon-log-out m-r-5"></i> {{'Logout' | translate}} </a></li>
        </ul>
      </div>
    </div>
  </li>
  <li>
    <div class="dropdown" ngbDropdown placement="auto">
      <a ngbDropdownToggle href="javascript:" data-toggle="dropdown"><i class="icon feather icon-bell"></i></a>
      <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right notification">
        <div class="noti-head">
          <h6 class="d-inline-block m-b-0">{{'Notifications' | translate}}</h6>
          <div class="float-right">
            <!-- <a href="javascript:" class="m-r-10">mark as read</a> -->
            <!-- <a  style="cursor: pointer;">clear all</a> -->
          </div>
        </div>
        <perfect-scrollbar [style.max-height]="'300px'">
        <ul class="noti-body" *ngIf="notificationlist?.length != 0 ">
          <li class="notification" *ngFor="let notification of notificationlist">
            <div class="media">
              <div class="media-body">
                <p><strong>{{ notification.title }}</strong></p>
                <p>{{ notification.body }}</p>
              </div>
            </div>
          </li>
        </ul>
        <ul class="noti-body" *ngIf="notificationlist?.length == 0 ">
          <li class="notification">
            <div class="media">
              <div class="media-body">
                <p><strong>NO Data</strong></p>
              </div>
            </div>
          </li>
        </ul>
        </perfect-scrollbar>
        <!-- <div class="noti-footer">
          <a href="javascript:" (click)="clear()">Clear all</a>
        </div> -->
      </div>
    </div>
  </li>
 
  <li>
    <div class="dropdown drp-user" ngbDropdown placement="auto">
      <a  ngbDropdownToggle data-toggle="dropdown" style="cursor: pointer;">
        <i class="icon feather icon-globe "></i>
      </a>
      <div class="dropdown-menu dropdown-menu-right profile-notification " ngbDropdownMenu>
        <div class="pro-head">
          <span>{{'Select Language' | translate}}</span>
        </div>
        <ul class="pro-body" style="color: white;">
            <li><a href="javascript:" class="dropdown-item" (click)="englishLang()">{{'English' | translate}}</a></li>
            <li><a href="javascript:" class="dropdown-item" (click)="frenchLang()"> {{'French' | translate}} </a></li>
        </ul>
      </div>
    </div>
  </li>
 
</ul>
<ng-snotify [ngClass]="style"></ng-snotify>
