// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAA37fmABuAKm8WCak9GQsYoBpMLu5ADnQ",
    authDomain: "alkira-b9526.firebaseapp.com",
    projectId: "alkira-b9526",
    storageBucket: "alkira-b9526.appspot.com",
    messagingSenderId: "510750715074",
    appId: "1:510750715074:web:2eef6a12b02051ac685f5b",
    measurementId: "G-QVSEQ644BJ",
    vapidKey: "BJZSWnnso504TvUVcRkQultknQMW-_y4Mzq6wvFz3C50VX3IwTAAO8dKwRQL4Vy6OaWdvmibVU-S2VTq9DNuddc" 
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
